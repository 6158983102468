import React from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      Please <Link to="/">return to the homepage</Link>.
    </p>
  </div>
);

export default NotFoundPage;
